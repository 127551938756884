<template>
  <div class="infoCard">
    <div class="card-info-box">
      <a-row>
        <a-col style="text-align: right">
          <a-button style="margin-bottom: 16px" type="primary" @click="toAddAddress">
            <a-icon type="plus"></a-icon>
            添加
          </a-button>
        </a-col>
      </a-row>
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
        rowKey="index"
      >
        <template slot="region" slot-scope="text, record">
          {{ record.provinceName }} {{ record.cityName }} {{ record.areaName }}
        </template>

        <template slot="defaultFlag" slot-scope="text, record">
          <a-switch checked-children="开" un-checked-children="关" :checked="record.defaultFlag === 1"
                    @change="setDefault($event, record)" />
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="toEditAddress(record)">
            <a-icon type="edit" />
            修改</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定要删除吗？" ok-text="确定" cancel-text="取消" @confirm="onDelete(record)">
            <a>
              <a-icon type="delete" />
              删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="toCopyAddress(record)">
            <a-icon type="copy" />
            复制</a>
        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <AddressModal ref="addressModal" @ok="handleLoadData(1)" />
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { getDateNewRange } from '@/util/utils'
import AddressModal from './modules/addressModal.vue'

export default {
  // name: 'ContractManagement',
  mixins: [ListMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup,
    AddressModal
  },

  data() {
    return {
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined
      },
      rangeDate: [],
      columns: [
        {
          title: '收件人 ',
          dataIndex: 'concat',
          key: 'concat',
          width: 120
        },
        {
          title: '电话',
          dataIndex: 'concatPhone',
          key: 'purchaseBusinessName',
          width: 180,
          scopedSlots: { customRender: 'concatPhone' }
        },

        {
          title: '所在区域',
          dataIndex: 'region',
          key: 'region',
          scopedSlots: { customRender: 'region' }
        },
        {
          title: '详细地址',
          dataIndex: 'address',
          align: 'center',
          key: 'address',
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '默认地址',
          dataIndex: 'defaultFlag',
          key: 'defaultFlag',
          width: 140,
          align: 'center',
          scopedSlots: { customRender: 'defaultFlag' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 210,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      quoteOptions: [
        {
          dictKey: '1',
          dictValue: '待生效'
        },
        {
          dictKey: '2',
          dictValue: '进行中'
        },
        {
          dictKey: '3',
          dictValue: '已过期'
        }
      ],
      otherOptions: [
        {
          dictKey: '0',
          dictValue: '本月'
        },
        {
          dictKey: '3',
          dictValue: '三个月'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        }
      ],
      mainOptions: [],
      activeInfo: {},
      requestType: 'post',
      url: {
        list: '/address/queryAllByLimit'
      },
      statusDictList: {
        2: '进行中',
        1: '待生效',
        3: '已过期',
        4: '已终止'
      },
      statusDictStyle: {
        2: 'status_4',
        1: 'status_2',
        3: 'status_10',
        4: 'status_10'
      },
      statusADictList: {
        0: '待生效',
        1: '待签署',
        2: '已签署 '
      },
      //  0:发起 1:签署中 2 签署完成
      statusADictStyle: {
        0: 'status_1',
        1: 'status_6',
        2: 'status_4'
      },
      createTimeScoped: '',
      createTimeRange: [],
      orderNo: null,
      disableMixinCreated: true
    }
  },
  created() {
    this.handleLoadData(1)
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeKind({ type, record }) {
      if (type == 1) {
        console.log(record, 111)
        this.$refs.signContractInfo.handleOpen(record)
      } else {
        this.handleUploadContract(record)
      }
    },
    handleChangeType({ record }) {
      this.$refs.UploadContract.handleOpen(record)

    },
    handleEmitContract(e) {
      this.$refs.signContractInfo.handleOpen(e)
    },
    handleViewInfo(item) {
      this.$refs.viewContract.handleOpen(item)
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = []
      }
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleInit() {
      getAction('/quote/dictionary').then((res) => {
        const { success, data } = res
        success && (this.mainOptions = data)
      })
    },
    handleJump(url) {
      window.open(url)
    },
    handleFileUpload(e) {
      let selectedFile = event.target.files[0]
      let formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('dir', 'images')
      uploadAction('/file/images/upload', formData).then((res) => {
        const { success, message, data } = res
        if (success) {
          // 触发签单接口
          postAction('/v1/contract/framework/offline/sign', {
            contractFile: data.url,
            contractNo: this.activeInfo.contractNo || ''
          }).then((res) => {
            const { success, message } = res
            if (success) {
              this.reaLoad()
            } else {
              this.$message.error(message)
            }
          })
        } else {
          this.$message.error(message)
        }
      })
    },
    handleUploadContract(item) {
      this.$refs.newUploader.handleOpen(item)
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    handleConfirmSplit(item) {
      this.$refs.splitOrderInfoRef.handleShowOrder({ ...item })
    },
    handleEmitSign(record) {
      this.$refs.ReNewSignRef.handleOpen(record)
    },
    /* 开启合同上传 */
    handleOPenContractUploader(item) {
      this.orderNo = item.orderNo
      // 开启抽屉
      this.$refs.UploadContract.handleOpen(item)
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 10,
          total: 0
        }
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        pagesSize: pageSize,
        pageNum,
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.dataSource = list
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
      this.queryParams = {}
      this.createTimeRange = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    toAddAddress() {
      this.$refs.addressModal.handleOpen({})
    },
    toEditAddress(item) {
      this.$refs.addressModal.handleOpen(item)
    },
    toCopyAddress(item) {
      delete item.id
      this.$refs.addressModal.handleOpen(item)
    },
    onDelete(record) {
      postAction("/address/deleteById",{id:record?.id}).then((res)=>{
        if (res.success) {
          this.handleLoadData(1)
        }
        else{
          this.$message.error(res.message)
        }
      })
    },
    onRegionChange(value, selectedOptions) {
      this.form.provinceName = selectedOptions[0].label
      this.form.cityName = selectedOptions[1].label
      this.form.areaName = selectedOptions[2].label

      this.form.provinceCode = selectedOptions[0].value
      this.form.cityCode = selectedOptions[1].value
      this.form.areaCode = selectedOptions[2].value
    },
    setDefault(value, record) {
      const payload = { id: record?.id }
      payload.defaultFlag = value ? 1 : 0
      postAction('/address/updateDefaultById', payload).then(res => {
        console.log(res)
        if (res.success) {
          this.handleLoadData()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;

    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';

::v-deep .ant-form-item-label {
  width: 67px !important;
}

.fileUploader {
  display: none;
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.inline-name {
  width: 100%;
  display: flex;
  align-items: center;

  span.label {
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

span.tag {
  display: inline-block;
  width: 32px;
  height: 20px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: #05c29c;
  margin-right: 6px;

  &.danger {
    background: #ee4261;
  }
}
</style>
